$(document).ready(function($){

    //mobile and tablet project page display
    $(function() {
        $('.project-list-project .clickable').click(function(){
            var dataTarget = $(this).attr("data-target");

            if(($(this).parents('.project-list-project:not(.active)')) && $(this).hasClass('collapsed')){
                $(this).parents('.project-list-project').addClass('active');
                $(this).parents('.project-list-project').siblings().removeClass('active');
                var panels = $(this).parents('#AccountProjectsList').find('[id^=heading]').siblings();
                panels.each(function(){
                    if($(this).attr("id") != dataTarget){
                        var id = '#'+$(this).attr("id");

                        $(id).collapse('hide');
                        $('[data-target="'+id+'"]').addClass('collapsed');
                    }
                });

            } else if ($(this).parents('.project-list-project').hasClass('active') && (!$(this).hasClass('collapsed'))){

                $(this).parents('.project-list-project').removeClass('active');
            }

        });
    });

});
