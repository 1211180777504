$(document).ready(function ($) {
  let addProductToProjectBtnSelector = "#add-product, #add-product-mobile",
    addProductToProjectModalSelector = "#addProductToProjectModal",
    addProductToProjectSaveBtnSelector = "#add-product-to-project",
    singleProductSelectElementSelector = "select.single-product-select-list",
    errorBlockClass = ".error-block",
    loadingSpinnerSelector = ".loading",
    errorElementSelectors = ".alert.alert-danger, .inline-error-block",
    addProductToProjectModalCloseSelectors =
      "#add-product-to-project-cancel, #add-product-to-project-close";

  $(addProductToProjectSaveBtnSelector).click(function () {
    $(addProductToProjectModalCloseSelectors).prop("disabled",true);
    $(this).off();
    $(this).children(loadingSpinnerSelector).show();
  });

  $(addProductToProjectModalCloseSelectors).click(function () {
    $(addProductToProjectModalSelector).modal("hide");
    $(addProductToProjectSaveBtnSelector).children(loadingSpinnerSelector).hide();
    $(singleProductSelectElementSelector).prop("selectedIndex", 0);
    $(errorElementSelectors).remove();
    $(errorBlockClass).removeClass(errorBlockClass.substring(1));
  });

  $(".show-hide-toggle").click(function () {
    var key = $(this).siblings(".text").attr("data-key");
    var str = $(this).siblings(".text").attr("value");
    if ($(this).text() === "Show") {
      $(this).text("Hide");
      $(this)
        .siblings(".text")
        .val(key)
        .prop("type", "text")
        .removeClass("dots");
    } else {
      $(this).text("Show");
      $(this)
        .siblings(".text")
        .val(str)
        .prop("type", "password")
        .addClass("dots");
    }
  });
});
