$(document).ready(function($){

  // Open login modal
  $('#login-dropdown-link').click(function(){
    $('#portal-login-dropdown').modal({
        backdrop: false,
        show: true
    });
  });

  $('#portal-login-dropdown').on('shown.bs.modal', function (e) {
    //remove modal padding-right automatically placed on body element
    $('#portal').attr('style','');
  });

  //Close login modal
  $('#close-login-modal').click(function(){
      $('#portal-login-dropdown').modal('hide');
  });

  // search form shown collapse event
  $('#navbarSearchCollapse').on('show.bs.collapse', function(){
    // hide the search icon
    $('#navbarSearchCollapseBtn').addClass('invisible').removeClass('visible');
  });

  // search form hidden collapse event
  $('#navbarSearchCollapse').on('hidden.bs.collapse', function(){
    // clear out the search form
    $('#search-input').val("");
    $('#hiddenSearchInput').val("");
    // remove the search results
    $('.tntsearch-results').empty();
    // show the search icon
    $('#navbarSearchCollapseBtn').removeClass('invisible').addClass('visible');
  });
  // fill in the hidden search value on keyup
  $('input#search-input').keyup(function() {
    //set the hidden search everytime a keyup happens in the main search
    $('#hiddenSearchInput').val($(this).val());
  });
  // when the go button is clicked submit the hidden search
  $('#submitHiddenSearch').click(function(){
    $('#hiddenSearch').submit();
  });
  //Nojs solution for login and user profile so user is redirected to
  // login or profile page
  function buttonClicked(event) {
    event.preventDefault();
  }
  $('#login-dropdown-link').on('click', buttonClicked);
  $('#profile-dropdown-link').on('click', buttonClicked);

  // Megamenu js

  // open mega menu
  $('#megaMenuCollapseBtn a').click(function (e) {
    if(!($('#megaMenuTab .tab-label.active').length > 0)) {
      $('#megaMenuTab #home-tab').addClass('active');
      $('#megaMenuTabContent #all').addClass('show active');
    }
  });

  // Top level tab links
  $("#megaMenuPanel .tab-label").click(function (e) {
    e.preventDefault();
    $("#usecase-panel .tier-1-col a").removeClass('active');
    $("#usecase-panel .tier-1-col.active").removeClass('active');
    var href = $(this).attr('href');
    $('#megaMenuTabContent .tab-pane').removeClass('show active');
    $('#megaMenuTabContent ' + href).addClass('show active');


  });
  // Tier 1 (leftmost column) links
  $("#usecase-panel .tier-1-col a").click(function (e){
    e.preventDefault();
    var href = $(this).attr('href');
    // remove active class from all tier 1 links; add to this one
    $("#usecase-panel .tier-1-col a").removeClass('active');
    $(this).addClass('show active');
    $(this).parents('.tier-1-col').addClass('active');
    $('#usecase-panel .tier-2-col .tab-pane').removeClass('show active');
    $('#usecase-panel .tier-2-col .menu-term').removeClass('show active');
    $('#usecase-panel .tier-2-col ' + href).addClass('show active');
    $("#usecase-panel .tier-2-col.active").removeClass('active');

  });

  // Tier 2 (second column from left) links
  $("#usecase-panel .tier-2-col .tab-pane a.menu-term").click(function (e) {
    var href = $(this).attr('href');
    $("#usecase-panel .tier-2-col .tier-3-col .tab-pane").removeClass('show active');
    $("#usecase-panel .tier-2-col .tier-3-col " + href).addClass('show active');
    $("#usecase-panel .tier-2-col").addClass('active');

  });

  $("#desktop-help-menu").click(function (e) {
    if (!($('#megaMenuCollapseBtn a').hasClass('collapsed'))) {
      $('#megaMenuCollapse').removeClass('show');
      $('#megaMenuCollapseBtn a').addClass('collapsed');
    }
  });

  // USB MegaMenu
  //
  //  js for mobile menu
  //
  $('#megaMenuCollapseMobileBtn .submenu-icon').click(function (e){

    // open and collapse chevron icon
    var sibling = $(this).siblings('.chevron');
    if (sibling.hasClass('collapsed')) {
      // open Products level 1
      sibling.removeClass('collapsed');
      $(this).addClass('active');
      $('.slide-links-wrapper').addClass('level-1');
    } else {
      // go back to Products level-1
      sibling.addClass('collapsed');
      $(this).removeClass('active');
      $('.slide-links-wrapper').removeClass('level-1');
    }

  });

  // desktop/mobile help menus
  $('#help-menu').click(function (e){
    // open and collapse chevron icon
    var sibling = $(this).siblings('.chevron');
    var pages = $(this).siblings("#help-pages, #help-pages-mobile");
    if (sibling.hasClass('collapsed')) {
      // open Products level 1
      sibling.removeClass('collapsed');
      pages.addClass('show');
      $(this).addClass('active');

    } else {
      // go back to Products level-1
      sibling.addClass('collapsed');
      pages.removeClass('show');
      $(this).removeClass('active');

    }
  });

// API Products list
  $('#allProductList').click(function (e){

    if($(this).hasClass('active')) {
      {
        // go to parent level
        $(this).removeClass('active');
        $(this).parent().removeClass('slide-right');
        $('.slide-links-wrapper').removeClass('level-2').addClass('level-1');
        $($(this).data("target")).removeClass('show');
        $(this).siblings('.back').removeClass('back').addClass('right-link');

        $(this).parent().siblings('li').removeClass('hide');
        $(this).parents('#megaMenuCollapseMobile').siblings('li').removeClass('hide');
      }
    } else {
        // go to api list
        $($(this).data("target")).addClass('show');
        $(this).addClass('active');
        $(this).parent().addClass('slide-right');
        $('.slide-links-wrapper').removeClass('level-1').addClass('level-2');
        $(this).siblings('.right-link').removeClass('right-link').addClass('back');

        $(this).parent().siblings('li').addClass('hide');
        $(this).parents('#megaMenuCollapseMobile').siblings('li').addClass('hide');

    }

  });

  // go to vocab level
  $('.vocab-label').click(function (e){

    if($(this).hasClass('active')) {
      {
        // go to parent level
        $(this).removeClass('active');
        $(this).parent().removeClass('slide-right');
        $('.slide-links-wrapper').removeClass('level-2').addClass('level-1');
        $($(this).data("target")).removeClass('show');
        //$(this).siblings('.right-link').removeClass('hide');
        $(this).siblings('.back').removeClass('back').addClass('right-link');

        $(this).parent().siblings('li').removeClass('hide');
        $(this).parents('#megaMenuCollapseMobile').siblings('li').removeClass('hide');

      }
    } else {
        // go to vocab level
        $($(this).data("target")).addClass('show');
        $(this).addClass('active');
        $(this).parent().addClass('slide-right');
        $('.slide-links-wrapper').removeClass('level-1').addClass('level-2');
        $(this).siblings('.right-link').removeClass('right-link').addClass('back');

        $(this).parent().siblings('li').addClass('hide');
        $(this).parents('#megaMenuCollapseMobile').siblings('li').addClass('hide');
    }

  });

  $('.vocab-term').click(function (e){

    if($(this).hasClass('active')) {
      // go to level-2
      $(this).parent().removeClass('slide-right');
      var siblings = $(this).parent('li.dropdown-submenu').siblings();
      siblings.removeClass('slide-hide');
      var parent = $(this).parents('ul.dropdown-menu').siblings('.vocab-label.active');
      parent.removeClass('slide-hide');
      $('.slide-links-wrapper').removeClass('level-3').addClass('level-2');
      $('#'+$(this).data("target")).removeClass('show');
      $(this).removeClass('active');
      $(this).siblings('.back').removeClass('back').addClass('right-link');

    } else {
      // go to level-3
      $(this).parent().addClass('slide-right');
      var siblings = $(this).parent('li.dropdown-submenu').siblings();
      siblings.addClass('slide-hide');
      var parent = $(this).parents('ul.dropdown-menu').siblings('.vocab-label.active');
      parent.addClass('slide-hide');
      $('.slide-links-wrapper').removeClass('level-2').addClass('level-3');

      $('#'+$(this).data("target")).addClass('show');
      $(this).addClass('active');
      $(this).siblings('.right-link').removeClass('right-link').addClass('back');
    }

  });

  $('.vocab-subterm').click(function (e){

    if($(this).hasClass('active')) {
      // go to level-3
      $(this).removeClass('active');
      $(this).parent().removeClass('slide-right');
      var siblings = $(this).parent('li.dropdown-submenu').siblings();
      siblings.removeClass('slide-hide');
      var parent = $(this).parents('ul.dropdown-menu').siblings('.vocab-term.active');
      parent.removeClass('slide-hide');
      $('.slide-links-wrapper').removeClass('level-4').addClass('level-3');
      $('#'+$(this).data("target")).removeClass('show');
      $(this).siblings('.back').removeClass('back').addClass('right-link');

    } else {
      // go to level-4
      $(this).addClass('active');
      $(this).parent().addClass('slide-right');
      var siblings = $(this).parent('li.dropdown-submenu').siblings();
      siblings.addClass('slide-hide');
      var parent = $(this).parents('ul.dropdown-menu').siblings('.vocab-term.active');
      parent.addClass('slide-hide');
      $('.slide-links-wrapper').removeClass('level-3').addClass('level-4');
      $('#'+$(this).data("target")).addClass('show');
      $(this).siblings('.right-link').removeClass('right-link').addClass('back');

    }

  });
  // mobile collapse functions
  $('#help-menu').click(function (e) {
    // collapse Products if it is open when Help is triggered
    if ($('#productsMenuMobile').hasClass('active')) {
      $('#productsMenuMobile').removeClass('active');
      $('#productsList').removeClass('show');
      var sibling = $('#productsMenuMobile').siblings('i.chevron');
      sibling.addClass('collapsed');
    }
  });

  $('#productsMenuMobile').click(function (e) {
    $(this).toggleClass('active');
    $(this).siblings('i.chevron').toggleClass('collapsed');
    // collapse Help if it is open when Products is triggered
    if ($('#help-menu').hasClass('active')) {
      $('#help-menu').removeClass('active');
      $('ul#help-pages, ul#help-pages-mobile').removeClass('show');
      var sibling = $('#help-menu').siblings('i.chevron');
      sibling.addClass('collapsed');
    }

  });

  // if screen is larger than lg size, space navigation bar profile elements
  let viewport = $(window).width(),
      width = $("#navbarSearchCollapseBtn + li").width();

  if ( viewport > 991 ) {
    $("#navbarSearchCollapseBtn").css("right", width);
  }

  $(".mobile_menu").simpleMobileMenu({
    "menuStyle": "slide"
  });

  $( "#sm_menu_ham").click(function() {
    $('.overlay-container').addClass('on');
    if($('#sm_menu_ham').hasClass('open')) {
        $('html').addClass('scroll-locked');
        $('.overlay-container').addClass('mobile-on');
        document.getElementById('body-wrap').style.pointerEvents = 'none';
        document.getElementById('footer').style.pointerEvents = 'none';
    } else {
      $('html').removeClass('scroll-locked');
      document.getElementById('body-wrap').style.pointerEvents = 'auto'; 
      document.getElementById('footer').style.pointerEvents = 'auto'; 
      $('.overlay-container').removeClass('mobile-on');
    }
  });

  $( "#portal-login-dropdown #close-login-modal").click(function() {
    $('.overlay-container').removeClass('desktop-on');
  });
  
  
  $( "#portal-profile-dropdown #close-profile-dropdown").click(function() {
    $( "#portal-profile-dropdown, .profile-dropdpwn-btn").removeClass('show');
    $('.overlay-container').removeClass('desktop-on');
  });
});

// Controll the overlays when screensize varies
$(window).resize(function() {         
  if ($(window).width() > 991.98) {
      $('.overlay-container').removeClass('mobile-on');
  } else {
      $('.overlay-container').removeClass('desktop-on');
      $('#sm_menu_ham').removeClass('open')
      $('.sm_menu_outer').removeClass('active')
      
  }
});




