$(document).ready(function($){

    $('.delete-project').click(function() {
        let id = $(this).data('id');

        $('#delete-project-id').val(id);
        $('#deleteProjectModal').modal('show');
    });

});
