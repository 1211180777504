$(document).ready(function($){
    // Todo: The error message in the alert is not using the language file, require proper fix for this.  
    // this is moved from the captcha.html.twig file, 
    // because of the following error message: Uncaught ReferenceError: $ is not defined in console. 
    $('form').submit(function(event) {
        if ($(this).has("#g-recaptcha-response").length && $("#g-recaptcha-response").val() === '') {
            event.preventDefault();
            alert("Error validating the Captcha");
        }
    });
});
