import $ from 'jquery';
window.$ = window.jQuery = $


import 'select2';
import 'jquery-validation';
import 'jquery-ui'; 
import '../../node_modules/jquery-ui/dist/jquery-ui';
import 'bootstrap';
import './components/DeleteProject';
import './components/Form';
import './components/Header';
import './components/ProjectPage';
import './components/ProjectsPage';
import './components/ResetKeys';
import './components/jquery.material.form.min';
import './components/slick.min';
import './components/jquery-simple-mobilemenu';
import 'bootstrap-table';
