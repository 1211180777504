$(document).ready(function($){

    let resetKeysSelector = ".reset-key";;

    function resetKeyClicked(e) {
        e.preventDefault();
        $("#reset-project-keys-cancel").prop("disabled", false);
        $(".close-modal").prop("disabled", false);
        $("#resetKeysConfirmationModal").modal("show");
    }

    $(resetKeysSelector).click(resetKeyClicked);

    $('#reset-project-keys').click(function(e) {
        const successMessage = "<div id=\"messages\" class=\"container\"><div class=\"alert alert-success info\"><i class=\"icon-alert icon-alert-checkmark\"></i>Your project key has been reset.<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button></div></div>";
        const errorMessage = "<div id=\"messages\" class=\"container\"><div class=\"alert alert-danger error\"><i class=\"icon-alert icon-alert-wrong\"></i>Your project key cannot be reset at this time. Please try again later.<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button></div></div>";

        e.preventDefault();
        $("#reset-project-keys-cancel").prop("disabled", true);
        $(".close-modal").prop("disabled", true);
        // make reset call to Apigee
        const projectID = $('.projectID').val();
        const nonceVal = $("input[name='ajax-nonce']").val();
        $(this).children('.loading').show();
        $(this).prop("disabled",true);
        $.ajax({
            url: '/ajax',
            data: {
                'task': 'resetProjectKeys',
                'projectID': projectID,
                'ajax-nonce': nonceVal
            }
        }).done(function(result){
            const data = JSON.parse(result);
            if(data !== null && data !== false && data !== ''){
                const consumerKey = data.credentials[0].consumerKey;
                const consumerSecret = data.credentials[0].consumerSecret;
                $("#key, #key-mobile").val(consumerKey);
                $("#secret, #secret-mobile").val(consumerSecret);
                $('#reset-project-keys').children('.loading').hide();
                $('#reset-project-keys').prop("disabled",false);
                $('#resetKeysConfirmationModal').modal('hide');
                $(".credentials-details").prepend(successMessage);

                return true;

            }else {
                $('#reset-project-keys').children('.loading').hide();
                $('#resetKeysConfirmationModal').modal('hide');
                $('#reset-project-keys').prop("disabled",false);
                $(".credentials-details").prepend($(errorMessage));

                return false;
            }

        }).fail(function(jqXHR, textStatus) {
            $('#reset-project-keys').children('.loading').hide();
            $('#resetKeysConfirmationModal').modal('hide');
            $('#reset-project-keys').prop("disabled",false);
            $(".credentials-details").prepend($(errorMessage));

            return false;
        });

    });
    // hide modal on page open
    $('#resetKeysConfirmationModal').modal('hide');
    $('#reset-project-keys').prop("disabled",false);
    // open confirmation modal
    $('#reset-project-keys-cancel').click(function(e) {
        e.preventDefault();
        $('#resetKeysConfirmationModal').modal('hide');
    });

});
